/* You can add global styles to this file, and also import other style files */
@import '_commons_colors.scss';
@import 'index';
@import 'flags';

*,
*::before,
*::after {
  box-sizing: border-box;
}

// Quill Overrides

/* added to make it expandable */
.ql-container,
.ql-editor {
  min-height: 90px;
  max-height: 400px;
  // overflow-y: scroll;
}

// Utils
.h-100 {
  height: 100%;
}
